import React from "react";
import RightArrowSvg from "./RightArrowSvg";

const ProjectTasksRelationshipAssigned = ({entry, from_group}) => {
  let { data } = entry
  let { new_user, past_user, project_task_relationship_type } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1">
        <span className="small"> updated relationship</span>{" "}
        {project_task_relationship_type}
      </h6>

      <div>
        {past_user}
        <RightArrowSvg />
        {new_user}
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksRelationshipAssigned
