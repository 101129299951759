import React from "react";
import RightArrowSvg from "./RightArrowSvg";

const ProjectTasksScheduleDurationSet = ({entry, from_group}) => {
  let { data } = entry
  let { new_duration, past_duration } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        schedule duration changed
      </h6>

      <div>
        {past_duration ? `${past_duration} min` : "No duration"}
        <RightArrowSvg />
        {new_duration ? `${new_duration} min` : "No duration"}
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
           {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksScheduleDurationSet
